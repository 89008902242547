import React from 'react'
import clsx from 'clsx'
import { Typography } from '@mui/material'
import styles from './CartContentItem.module.scss'
import { ShoppingCartItem } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { TextSkeleton } from '../../../text-skeleton/TextSkeleton'
import { ImgProxyImage } from '../../../img-proxy-image/ImgProxyImage'
import { ensureValidImgProxyUrl } from '@obeta/utils/lib/ensureValidImgProxyUrl'

interface ICartContentItemProps {
  item: ShoppingCartItem
  isLoading?: boolean
}

export const CartContentItem: React.FC<ICartContentItemProps> = (props) => {
  const { item, isLoading } = props
  const { product } = item
  const validUrl = ensureValidImgProxyUrl(product.images?.[0]?.url)

  return (
    <div className={styles['item-wrapper']}>
      <div
        className={clsx(styles['item-image'], {
          [styles['product-image-broken']]: !validUrl,
        })}
      >
        {validUrl && (
          <ImgProxyImage
            variant="product"
            className={styles['product-image']}
            url={validUrl}
            title={`Art-Nr. ${product.dehaId}`}
            mobileWidth={48}
            tabletWidth={48}
            tabletWideWidth={48}
            desktopWidth={48}
          />
        )}
      </div>
      <div className={styles['item-info-wrapper']}>
        <Typography className={styles['item-title']} variant="bodyBold">
          {isLoading ? <TextSkeleton height={20} /> : product.title}
        </Typography>
        <Typography variant="smallText" className={styles['item-article-id']}>
          Art-Nr. {product.dehaId}
        </Typography>
      </div>
    </div>
  )
}
